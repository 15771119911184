*{
  box-sizing: border-box;
}

html, body, #root, .App{
  width: 100%;
  height: 100%;
  padding:0;
  margin:0;
  position: relative;
}

a{
  outline: none;
  text-decoration: none;
}

#chartdiv > div > svg > g > g:nth-child(2) > g:nth-child(2) > g > g:nth-child(3){
  display: none;
}